.contact {
    display: flex;
    height: 100%;
    padding: 1.5rem 1%;
    justify-content: space-between;
}

.contact-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    background-color: var(--sec-color);
    border-radius: 20px;
}

.right-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.6rem;
}

.right-content .send-btn button {
    border: none;
    color: var(--sec-text-color);
    border-radius: 10px;
    padding: 0.6rem 4rem;
    background: var(--main-color);
}

.top-col {
    display: flex;
    flex-direction: column;
}

.right-content h4 {
    margin-top: 0rem;
    font-size: 1.6rem;
    color: var(--main-color);
}

.top-col textarea,
.top-col input {
    border: none;
    border-radius: 10px;
}

.top-col textarea::placeholder,
.top-col input::placeholder {
    padding: 0.6rem;
    font-size: 1rem;
}

.top-col input {
    height: 55px;
    margin-top: 2rem;
}

.top-col textarea {
    margin: 2rem 0;
    height: 120px;
}

.contact-left {
    width: 65%;
    height: 100%;
}

.left-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
}

.contact-card {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    margin-bottom: 3.5rem;
    padding: 0.6rem 1.5rem;
}

.contact-card-content {
    line-height: 3.5rem;
    margin: 0;
    padding: 1.5rem 2rem;
}

.contact-card-content p,
.contact-card-content h5 {
    font-weight: 620;
    margin: 0;
    font-size: 1.4rem;
}

.contact-card-content h5 {
    color: var(--main-color);
}

.contactIconsBG {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    padding: 1rem;
    color: var(--sec-color);
    border-radius: 20%;
    background-color: var(--main-color);
}

@media screen and (max-width: 1360px) {

    .contact-card {
        margin-bottom: 3.5rem;
        padding: 0.6rem 1.5rem;
    }

    .contact-card-content {
        line-height: 2.5rem;
        margin: 0;
        padding: 1rem 2rem;
    }

    .contact-card-content p,
    .contact-card-content h5 {
        font-weight: 620;
        margin: 0;
        font-size: 1.1rem;
    }

    .contact-right {
        width: 33%;
    }


    .right-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.2rem;
    }

    .right-content h4 {
        margin-top: 0rem;
        font-size: 1.4rem;
        color: var(--main-color);
    }

    .top-col textarea,
    .top-col input {
        border: none;
        border-radius: 10px;
    }

    .top-col textarea::placeholder,
    .top-col input::placeholder {
        padding: 0.6rem;
        font-size: 1rem;
    }

    .top-col input {
        height: 45px;
        margin-top: 1.5rem;
    }

    .top-col textarea {
        margin: 2rem 0;
        height: 90px;
    }


}

@media screen and (max-width: 1070px) {

    .contact-card {
        margin-bottom: 3rem;
        padding: 0.6rem 1.5rem;
    }

    .contact-card-content {
        line-height: 1.7rem;
        margin: 0;
        padding: 1rem 1.8rem;
    }

    .contact-card-content p,
    .contact-card-content h5 {
        font-weight: 550;
        margin: 0;
        font-size: 1rem;
    }

    .contact-right {
        width: 40%;
    }


    .right-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.2rem;
    }

    .right-content h4 {
        margin-top: 0rem;
        font-size: 1.2rem;
        color: var(--main-color);
    }

    .top-col textarea,
    .top-col input {
        border: none;
        border-radius: 10px;
    }

    .top-col textarea::placeholder,
    .top-col input::placeholder {
        padding: 0.6rem;
        font-size: 0.8rem;
    }

    .top-col input {
        height: 40px;
        margin-top: 1.2rem;
    }

    .top-col textarea {
        margin: 2rem 0;
        height: 90px;
    }

    .contactIconsBG {
        font-size: 2.5rem;
        padding: 1rem;
    }

}

@media screen and (max-width: 850px) {
    .contact {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }


    .contact-card-content {
        margin: 0;
        padding: 1rem 1.8rem;
    }

    .contact-card-content p,
    .contact-card-content h5 {
        font-weight: 550;
        margin: 0;
        font-size: 1rem;
    }

    .contact-right {
        margin-bottom: 3rem;
        width: 60%;
    }

    .contact-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .left-content {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {

    .contact-card {
        margin-bottom: 2rem;
        padding: 0.6rem 1.5rem;
    }

    .contact-card-content {
        line-height: 1.5rem;
        margin: 0;
        padding: 1rem 1.8rem;
    }

    .contact-card-content p,
    .contact-card-content h5 {
        font-weight: 500;
        margin: 0;
        font-size: 0.8rem;
    }

    .contactIconsBG {

        font-size: 1.7rem;
        padding: 0.5rem;

    }

    .contact-right {
        width: 100%;
    }

    .right-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.2rem;
    }

    .right-content h4 {
        margin-top: 0rem;
        font-size: 1.2rem;
        color: var(--main-color);
    }

    .top-col textarea,
    .top-col input {
        border: none;
        border-radius: 10px;
    }

    .top-col textarea::placeholder,
    .top-col input::placeholder {
        padding: 0.6rem;
        font-size: 0.8rem;
    }

    .top-col input {
        height: 40px;
        margin-top: 1.2rem;
    }

    .top-col textarea {
        margin: 2rem 0;
        height: 90px;
    }


}