.pudactPage {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 100vw;
}

.prod-contanier {
    width: 80vw;
    margin: 1.5rem 0% 0 0%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-bottom: 10%;
    justify-items: center;
    justify-self: center;

}


.PagiNation {
    position: absolute;
    bottom: 1rem;
}

.PagiNation .page-link {
    color: var(--main-color) !important;
}

.PagiNation .active>.page-link,
.page-link.active {
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
    color: var(--sec-text-color) !important;
    border-radius: 4px;


}

.PagiNation .disabled>.page-link,
.page-link.disabled {
    background-color: rgb(219, 219, 223) !important;
    border-color: rgb(219, 219, 223) !important;
}


.pageH {
    margin: 1rem 0rem;
    padding: 0 4% !important;
    overflow: hidden;

    p,
    a {
        margin: 0 0.5rem;
    }

    a:hover {
        color: var(--main-color);
    }
}


.OP-container {
    position: relative;
    background: var(--sec-color);
    padding: 0.7rem 0;
    width: 100%;
    min-height: 2.5rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--main-color);
    justify-content: space-around;
    align-items: center;

    .selected {
        background-color: var(--main-color-op);
        color: var(--sec-color);
        font-weight: bold;
    }
}

.brand-filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#brandFilter,
.sorting-container,
.sortOPS {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

#brandFilter {
    width: 70%;
    position: absolute;
    z-index: 10;
    top: 3.8rem;
    padding: 0.5rem;
    border-radius: 0 0 15px 15px;
    background-color: var(--sec-color);
}


.filterItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    margin: 0.3rem;
    border-radius: 15px;
    background-color: #fff;
    cursor: pointer;
    padding: 0.4rem 0.6rem;
}

@media screen and (max-width: 1816px) {
    .prod-contanier {
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
    }
}


@media screen and (max-width: 1551px) {
    .prod-contanier {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1565px) {


    .prod-contanier {
        width: 100%;
    }
}

@media screen and (max-width: 1392px) {
    .prod-contanier {
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
    }

}

@media screen and (max-width: 1360px) {
    .prod-contanier {
        width: 95%;
    }
}

@media screen and (max-width: 1200px) {
    .prod-contanier {
        grid-template-columns: repeat(4, 1fr);
        width: 90%;
    }
}


@media screen and (max-width: 1050px) {
    .prod-contanier {
        padding-bottom: 20%;
        grid-template-columns: repeat(4, 1fr);
    }

    .pudactPage {
        min-height: 60vh;
    }

}

@media screen and (max-width: 900px) {
    .prod-contanier {
        padding-bottom: 20%;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 765px) {
    .prod-contanier {
        width: 80%;
    }
}

@media screen and (max-width: 660px) {
    .prod-contanier {
        grid-template-columns: repeat(2, 1fr);
        width: 70%;
    }
}


@media screen and (max-width: 516px) {
    .prod-contanier {
        width: 75%;
    }

    .OP-container {
        position: relative;
        background: var(--sec-color);
        padding: 0.7rem 0;
        width: 100%;
        min-height: 2.5rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        border-top: 1px solid var(--main-color);
        justify-content: space-around;
        align-items: center;


    }

    .brand-filter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #brandFilter,
    .sorting-container,
    .sortOPS {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

    }

    .sorting-container .sortP {
        font-size: 0.6rem;
    }

    .filterItem {
        font-size: 0.6rem;
        padding: 0.4rem 0.45rem;
    }

    #brandFilter {
        width: 95.5%;
    }

    .pageH {

        p,
        a {
            margin: 0 0.2rem;
        }
    }

    .pageH,
    .PHname {
        font-size: 0.6rem;
        height: 1rem;
    }

    .PHname {
        display: none;
    }


}

@media screen and (max-width: 492px) {
    .prod-contanier {
        width: 95%;
    }
}

@media screen and (max-width: 450px) {
    .prod-contanier {
        padding-bottom: 25%;
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
    }

}

@media screen and (max-width: 424px) {
    .prod-contanier {
        width: 105%;
    }
}

@media screen and (max-width: 415px) {
    .prod-contanier {
        width: 80%;
    }
}

@media screen and (max-width: 390px) {
    .prod-contanier {
        width: 85%;
    }
}


@media screen and (max-width: 375px) {
    .prod-contanier {
        padding-bottom: 30%;
        /* grid-template-columns: repeat(1, 1fr); */
    }
}

@media screen and (max-width: 360px) {
    .prod-contanier {
        width: 90%;
    }
}

@media screen and (max-width: 344px) {
    .prod-contanier {
        width: 95%;
    }
}

@media screen and (max-width: 320px) {
    .prod-contanier {
        width: 117%;

    }
}