.newarrival .containerr {
    width: 79%;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.newarrival-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pudact-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--sec-color);
    padding: 0.4rem;
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1360px) {
    .newarrival {
        margin: 0 10%;
    }

    .newarrival .containerr {
        width: 98%;
    }
}

@media screen and (max-width: 1320px) {
    .newarrival {
        margin: 0 10%;
    }

    .newarrival .containerr {
        width: 100%;

    }
}

@media screen and (max-width: 1280px) {
    .newarrival {
        margin: 0 8.5%;
    }
}

@media screen and (max-width: 1220px) {
    .newarrival {
        margin: 0 6%;
    }
}



@media screen and (max-width: 1070px) {

    .newarrival .item {
        width: 170px;
        height: 265px;
        margin: 0;
        border-radius: 12px;
    }

    .newarrival .item button {
        font-size: 0.75rem;

    }


    .newarrival .product-labol {
        font-size: 0.6rem;
        border-radius: 4% 12px 0 4%;
    }

    .newarrival .prodImg {
        position: relative;
        padding: 0.3rem;
        display: flex;
        gap: 0.35rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        color: #2222224d;
    }

    .newarrival .prodImg img {
        position: relative;
        margin-top: 0.3rem;
        object-fit: fit;
        width: 140px;
        height: 110px;
    }

    .newarrival .product-imgs {
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10% 0 10%;
    }

    .newarrival .product-img {
        border: 1px solid rgba(255, 0, 0, 0);
        width: 100%;
        height: 10px;
        padding-top: 50%;
        margin-bottom: 10px;

    }

    .newarrival .product-img:hover img {
        opacity: 1;
    }

    .newarrival .product-img img {
        width: 100%;
        height: 1px;
        border: 1px solid var(--main-color);
        margin-bottom: 10px;
        opacity: 0.4;
    }

    .prodContent {
        width: 100%;
        padding: 0.2rem 0.6rem;
    }

    .newarrival .itemBrand {
        margin-top: 0.6rem;
    }

    .newarrival .itemModel,
    .newarrival .itemBrand {
        padding: 0.6rem 0.2rem;
        line-height: 0.4rem;
        font-size: 0.6rem;
        font-weight: bolder;
    }

    .newarrival .itemName {
        font-size: 0.7rem;
        height: 2.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }


}

@media screen and (max-width: 768px) {
    .newarrival {
        margin: 0 2%;
    }
}

@media screen and (max-width: 687px) {
    .pudact-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 385px) {
    .newarrival-header {
        padding: 0 4%;
    }
}