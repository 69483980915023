.catygoryitems {
    display: flex;
    justify-content: center;
    height: 100%;

}

.catygory-main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;

}

.catygoryitem {
    background: var(--sec-color);
    margin: 1.4rem;
    padding-bottom: 0.6rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    transition: all 0.2s linear;
    box-shadow: 7px 5px 20px -6px var(--main-text-color);
}

.catygoryitem:hover {
    z-index: 1;
    transform: translateY(-10px);
    box-shadow: 1px 5px 20px -6px var(--main-text-color);
}

.cateImgs-container {
    width: 320px;
    height: 200px;
}

.catygoryitem img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;
    border-radius: 20px 20px 0 0;
}

.catygoryitem h2 {
    padding-top: 0.5rem;
    bottom: 1.8rem;
    font-size: 1rem;
}