.prodcard {
    margin: 0.2rem;
    display: flex;
    justify-content: center;
}

.item {
    background-color: #fff;
    border: 1px solid var(--sec-color);
    padding: 0.1rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 280px;
    height: 375px;
    margin: 0.4rem;
    transform: translateY(0);
    transition: all 0.2s linear;

}


.item:hover {
    z-index: 1;
    transform: translateY(-10px);
    box-shadow: 1px 5px 20px -6px var(--main-text-color);

    .product-labol {
        transition: all 0.3s linear;
        opacity: 0.3;
    }

    .product-labol:hover {
        opacity: 1;
    }
}

.iicon {
    position: absolute;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    left: 3%;
    top: 2.5%;
    width: 11%;
    border: none;
    color: var(--main-color);
    background-color: #FFF;
    border-radius: 100%;
    padding: 0 0.2rem;
    font-size: 1.4em;
    cursor: pointer;
    transition: all 0.2s linear;
}

.iicon svg {
    opacity: 0.7;
    transition: all 0.2s linear;
}

.iicon svg:hover {
    opacity: 1;
    transition: all 0.2s linear;
}

.prodContent {
    width: 100%;
    padding: 0 0.6rem;
}

.prudactBrand {
    padding: 1.5rem 0.2rem 0 0.2rem;
}


.close-btn {
    color: #000 !important;
    border: none;
    background: transparent;
}



.prodImg {
    position: relative;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    color: #2222224d;
}

.product-imgs {
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10% 0 10%;
}

.prodImg img {
    position: relative;
    margin-top: 0.2rem;
    object-fit: cover;
    width: 185px;
    height: 155px;
}


.product-img {
    border: 1px solid rgba(255, 0, 0, 0);
    width: 100%;
    height: 10px;
    padding-top: 50%;
    margin-bottom: 10px;

}

.product-img:hover img {
    opacity: 1;
}

.product-img img {
    width: 100%;
    height: 1px;
    border: 1px solid var(--main-color);
    margin-bottom: 10px;
    opacity: 0.4;
}

.product-labol {
    position: absolute;
    display: flex;
    z-index: 3;
    top: 0px;
    right: 0px;
    background: var(--main-color);
    overflow: hidden;
    margin-bottom: 0.1rem;
    border-radius: 4% 20px 0 4%;
    color: var(--sec-text-color);
}

.product-labol-new,
.product-labol-discount {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem 0.5rem;
    gap: 0.5;
    transition: all 0.3s linear;
    margin: 0;
}



.itemModel,
.itemBrand {
    padding: 0.8rem 0.2rem;
    line-height: 0.6rem;
    font-size: 0.6rem;
    font-weight: bolder;
}

.itemName {
    padding: 0.3rem 0.2rem;
    font-size: 0.8rem;
    height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item button {
    position: absolute;
    bottom: 12px;
    width: 90%;
    height: 2rem;
    font-size: 1.1em;
    background-color: rgb(22, 66, 141);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 10px;
    transition: all 0.2s linear;
}

.item button:hover {
    transition: all 0.2s linear;
    background-color: #222;

}


/* Popup starts */

.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.3s;
}

.popup-content {
    position: relative;
    border-radius: 6px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 4% 4rem;
    text-align: center;
    max-width: 90%;
    max-height: 100%;
    overflow: hidden;
}

.title-section {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    width: 100%;
    color: #fff;
    padding-top: 0.5rem;
    margin-bottom: 2rem;
}

.popup-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
    margin-top: 5%;
}


.popup-content .Pimgs-container {
    width: 450px;
    height: 330px;
    display: flex;
    align-items: center;
    object-fit: cover;
    gap: 0.8rem;
}

.popup-content .Pimgs-container .popup-product-imgs {
    width: 100%;
}


.popup-content .Pimgs-container .coverImg {
    width: 80%;
}


.popup-product-img img {
    width: 50px;
    margin-bottom: 0.2rem;
}

.popup-text {
    margin: 0 1rem;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.popup-text p {
    font-size: 1rem;
    text-align: start;
}

.popup-btn {
    background: var(--main-color);
    width: 70%;
    margin: 2% 20%;
    padding: 0.7rem 0;
    border-radius: 15px;
    text-align: center;
}

.popupName {
    font-weight: bold;
}

.popup-description {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.popup-description a {
    width: 100%;
    text-align: end;
    margin: 0;
    padding: 0;
    font-size: 80%;
    color: var(--main-color);
    font-weight: bold;
}



.popup-description p {
    margin-top: 1%;
    max-height: 5.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.msgs-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.4rem 0;

    p {
        font-size: 0.8rem;
        margin: 0;
    }
}

.msg-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 0.4rem 0;

    p {
        font-size: 0.8rem;
        margin: 0;
    }
}

.msg-info input {
    width: 100%;
    outline: none;
    border-radius: 6px;
    border: 2px solid transparent;
}

.msg-info input:focus {
    border: 2px solid var(--main-color-op);

}

.msg-info input::placeholder {
    padding: 0 4px;
    font-size: 0.7rem;

}

.msgs-info-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.order-content {
    background-color: var(--sec-color);
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}


.item .ratingStars {
    font-size: 1rem;
}


@media screen and (max-width: 1600px) {


    .item {
        width: 240px;
        height: 335px;
        margin: 0;
        border-radius: 12px;
    }

    .product-labol {
        font-size: 1rem;
        border-radius: 4% 12px 0 4%;
    }

    .prodImg {
        position: relative;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        color: #2222224d;
    }

    .prodImg img {
        position: relative;
        margin-top: 0.2rem;
        object-fit: fit;
        width: 170px;
        height: 150px;
    }

    .product-imgs {
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10% 0 10%;
    }

    .product-img {
        border: 1px solid rgba(255, 0, 0, 0);
        width: 100%;
        height: 10px;
        padding-top: 50%;
        margin-bottom: 10px;

    }

    .product-img:hover img {
        opacity: 1;
    }

    .product-img img {
        width: 100%;
        height: 1px;
        border: 1px solid var(--main-color);
        margin-bottom: 10px;
        opacity: 0.4;
    }

    .itemModel,
    .itemBrand {
        padding: 0.7rem 0.2rem;
        line-height: 0.4rem;
        font-size: 0.7rem;
        font-weight: bolder;
    }

    .itemName {
        padding: 0;
        font-size: 0.8rem;
        height: 2.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}

@media screen and (max-width: 1410px) {


    .item {
        width: 200px;
        height: 295px;
        margin: 0;
        border-radius: 12px;
    }

    .item button {
        font-size: 1rem;

    }


    .product-labol {
        font-size: 0.8rem;
        border-radius: 4% 12px 0 4%;
    }

    .prodImg {
        position: relative;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        color: #2222224d;
    }

    .prodImg img {
        position: relative;
        margin-top: 0.2rem;
        object-fit: fit;
        width: 150px;
        height: 120px;
    }

    .product-imgs {
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10% 0 10%;
    }

    .product-img {
        border: 1px solid rgba(255, 0, 0, 0);
        width: 100%;
        height: 10px;
        padding-top: 50%;
        margin-bottom: 10px;

    }

    .product-img:hover img {
        opacity: 1;
    }

    .product-img img {
        width: 100%;
        height: 1px;
        border: 1px solid var(--main-color);
        margin-bottom: 10px;
        opacity: 0.4;
    }

    .itemModel,
    .itemBrand {
        padding: 0.7rem 0.2rem;
        line-height: 0.4rem;
        font-size: 0.6rem;
        font-weight: bolder;
    }

    .itemName {
        font-size: 0.7rem;
        padding: 0;
        height: 2.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}



@media screen and (max-width: 1360px) {
    .item .ratingStars {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 765px) {

    .item {
        width: 170px;
        height: 265px;
        margin: 0;
        border-radius: 12px;
    }

    .item button {
        font-size: 0.75rem;

    }


    .product-labol {
        font-size: 0.6rem;
        border-radius: 4% 12px 0 4%;
    }

    .prodImg {
        position: relative;
        padding: 0.3rem;
        display: flex;
        gap: 0.35rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        color: #2222224d;
    }

    .prodImg img {
        position: relative;
        margin-top: 0.3rem;
        object-fit: fit;
        width: 140px;
        height: 110px;
    }

    .product-imgs {
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10% 0 10%;
    }

    .product-img {
        border: 1px solid rgba(255, 0, 0, 0);
        width: 100%;
        height: 10px;
        padding-top: 50%;
        margin-bottom: 10px;

    }

    .product-img:hover img {
        opacity: 1;
    }

    .product-img img {
        width: 100%;
        height: 1px;
        border: 1px solid var(--main-color);
        margin-bottom: 10px;
        opacity: 0.4;
    }

    .prodContent {
        width: 100%;
        padding: 0.2rem 0.6rem;
    }

    .itemBrand {
        margin-top: 0.6rem;
    }

    .itemModel,
    .itemBrand {
        padding: 0.6rem 0.2rem;
        line-height: 0.4rem;
        overflow: hidden;
        height: 0.8rem;
        font-size: 0.5rem;
        font-weight: bolder;
    }

    .itemName {
        padding-top: 0.3rem;
        font-size: 0.7rem;
        height: 2.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .popup-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3%;
        margin-top: 5%;
    }

    .Pimgs-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .popup-product-imgs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 0.2rem;
    }

    .popup-content {
        overflow: scroll;
        overflow-x: hidden;
    }

}

@media screen and (max-width: 415px) {

    .item {
        width: 140px;
        height: 235px;
        margin: 0;
        border-radius: 12px;
    }

    .item button {
        font-size: 0.6rem;

    }

    .prodImg img {
        position: relative;
        margin-top: 0.3rem;
        object-fit: fit;
        width: 120px;
        height: 90px;
    }

    .product-imgs {
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10% 0 10%;
    }

    .product-img {
        border: 1px solid rgba(255, 0, 0, 0);
        width: 100%;
        height: 10px;
        padding-top: 50%;
        margin-bottom: 10px;

    }

    .product-img:hover img {
        opacity: 1;
    }

    .product-img img {
        width: 100%;
        height: 1px;
        border: 1px solid var(--main-color);
        margin-bottom: 10px;
        opacity: 0.4;
    }

    .itemModel,
    .itemBrand {
        height: 1rem;
        padding: 0.4rem 0.2rem;
        line-height: 0.4rem;
        font-size: 0.4rem;
        font-weight: bolder;
    }

    .itemName {
        font-size: 0.6rem;
        height: 2.5rem;
        padding-top: 0.7rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .msgs-info-row {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .item .ratingStars {
        font-size: 0.7rem;
    }

    .iicon {
        width: 9%;
        font-size: 1.1em;

    }
}