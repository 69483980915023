/* Basic styling for the navbar */
#navbar {
    height: 100%;
}

.navbar {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    transition: all 0.3s ease-in-out;
}


.navhidden {
    transition: all 0.3s ease-in-out;
    transform: translateY(-110%);
    opacity: 0;

}

.fakenave {
    width: 100%;
    height: 7rem;
}

.nav-items .hide {
    display: none !important;
}

.main-nav {
    padding: 0 7% 0 8.5%;
    width: 100%;
    position: fixed;
    height: 5rem;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    display: flex;
    background-color: var(--sec-color);
    color: var(--text-color);
    justify-content: space-around;
    align-items: center;
}

.lng-btn {
    position: absolute;
    content: '';
    top: 35%;
    left: 7%;
    text-align: center;
}

.lng-btn button {
    padding: 2px 6px 2px 6px;
    margin-bottom: 0;
    text-decoration: none;
    background: transparent;
    border: none;
    font-size: 12px;
}


.lng-btn button.selected {
    background-color: var(--main-color);
    border-radius: 25%;
    color: #fff;
}


.logo img {
    float: inline-end;
    height: 40px;
}

.nav-items {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


.nav-items li {
    cursor: pointer;
}

.nav-item i {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item a {
    color: var(--main-text-color);
}

.dropdown {
    display: flex;
}

.dropdown a {
    color: var(--main-text-color);
}

.dropdown i {
    padding-top: 5px;
    font-size: 22px;
}

.cont-btn {
    background: var(--main-color);
    color: var(--sec-text-color) !important;
    padding: 0.4rem 1.2rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.8rem;
}

.links {
    display: flex;
}

.links i {
    padding: 0.4rem;
    border-radius: 0.6rem;
    font-size: 1.2rem;
    background-color: var(--main-text-color-op);
    color: var(--sec-text-color);
    margin: 0 0.5rem;
}

.main-nav .dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    top: 130%;
    left: 0%;
    width: 235px;
    height: 255px;
    background-color: var(--sec-text-color);
    color: var(--main-text-color);
    list-style: none;
    margin: 0;
    border-radius: 0.4rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-item {
    padding: 0.5rem 0.5rem;

}


.sec-navbar {
    width: 100%;
    z-index: 100;
    position: fixed;
    margin-top: 4rem;
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    background-color: var(--main-color);
    color: var(--sec-text-color);
    transition: all 0.3s ease-in-out;
}

.sec-navbar .containerr {
    display: flex;

}

.sec-navbar a {

    color: var(--sec-text-color);
}


.sec-navbar .dropdown-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    top: 130%;
    left: 0%;
    width: 180%;

    background-color: var(--sec-text-color);
    color: var(--main-text-color);
    list-style: none;
    margin: 0;
    border-radius: 0.4rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.sec-navbar .dropdown-menu ul {
    width: 100%;
    padding: 0.8rem 0;
    display: flex;
    justify-content: start;
    text-align: start;
    flex-direction: column;
}

.sec-navbar .dropdown-menu li {
    width: 100%;
}

.sec-navbar .dropdown-menu li a {
    color: var(--main-text-color);
    width: 100%;
    padding: 0.6rem;
    display: flex;
    justify-content: start;
    text-align: start;
}


.sec-navbar .dropdown-menu li a:hover {
    background-color: var(--main-color);
    color: var(--sec-text-color);
}

.nav-menu-btn {
    color: var(--main-color);
    font-size: 2rem;
    padding-bottom: 0.62rem;
    display: none;

}

@media screen and (max-width: 1366px) {
    .logo img {
        top: 32%;
        right: 6%;
        height: 30px;
    }
}

@media screen and (max-width: 1250px) {
    .main-nav .nav-item {
        margin-left: 2.5rem;
    }

}

@media screen and (max-width: 1070px) {
    .main-nav {
        padding: 0 7%;
    }

    .logo img {
        height: 28px;
    }

    .main-nav .nav-item {
        margin-left: 2rem;

    }

    .main-nav .nav-items {
        margin-left: 1.2rem;

    }

    .sec-navbar .nav-items {
        font-size: 0.8rem;
    }

    .sec-navbar .dropdown-menu li a {
        font-size: 0.8rem;
    }
}


/* nav-menu-sied-bar */

@media screen and (max-width: 935px) {

    .main-nav {
        height: 3.4rem;
    }

    .navbar {
        position: initial;
    }

    #navbar .nav-hide {
        display: none;
    }

    .fakenave {
        width: 100%;
        height: 2.4rem;
    }

    .nav-menu-btn {
        padding-bottom: 0.29rem;
        font-size: 1.5rem;
        display: block;
    }

    .logo img {
        top: 35%;
        right: 6%;
        height: 25px;
    }
}

@media screen and (max-width: 660px) {
    .logo img {
        top: 42%;
        right: 6%;
        height: 20px;
    }
}

@media screen and (max-width: 490px) {
    .logo img {
        top: 42%;
        right: 6%;
        height: 15px;
    }
}