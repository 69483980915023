/* .login .container {}

.login .bg-image {
    width: 100% !important;
}

.container-fluid {
    padding-right: calc(var(--bs-gutter-x)) !important;
    padding-left: calc(var(--bs-gutter-x)) !important;
} */

.card-body {
    border: none;
    border-radius: 20px;
    background-color: var(--sec-color);
}

.login .card {
    border: none;
}