.showcase {
    width: 100%;
    padding-bottom: 2rem;
}

.sticky-i {
    position: fixed;
    bottom: -6%;
    right: 2%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999999;
}

.sticky-i a {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    padding: 14px;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    color: rgb(255, 255, 255);
    font-size: 24px;
    border-radius: 0.6rem;
}

.S-btn {
    background: #3a5998c0;
}

.S-btn:hover {
    background: #3A5998;
}


.sticky-i .hidden {
    cursor: default;
    opacity: 0 !important;
}


/* 
.carousel-control-next-icon,
.carousel-control-prev-icon {} */

.showcase img {
    object-fit: cover;
    width: 100%;
    height: 60vh;
}