.ProductD-Page {
    min-height: 100%;
    height: 100%;
    margin: 2rem 6% 1rem 6%;
    display: flex;
    justify-content: center;

}


.productD {
    display: flex;
    width: 90%;
    /* align-items: center; */
    justify-content: space-between;
}

.imgshowcase {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 45%;
}

.Pimg-cover {
    position: relative;
    transition: all 0.3 linear;


    .glassIcon {
        display: none;
    }
}

.Pimg-cover .product-labol {
    right: 1rem;
    border-radius: 2px;

}

.Pimg-cover:hover {
    transition: all 0.3 linear;

    .glassIcon {
        display: block;
    }

    .product-labol {
        display: none;
    }
}

.glassIcon i {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 0.6rem;
    background-color: rgba(0, 0, 0, 0.4);
}

.glassIcon {
    position: absolute;
    top: 40%;
    right: 45%;
    color: #fff;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 2rem;
    z-index: 1 !important;

}

.Pimg-cover img {
    width: 100%;
    padding: 0 1rem;
}


.Pimgs {
    max-width: 70px;
    display: flex;
    flex-direction: column;

}

.Pimgs img {
    padding: 2px 0;
    opacity: 0.7;
    width: 100%;
}

.Pimgs img:hover {
    opacity: 1;
}


.productDinfo {
    width: 50%;

    h5 {
        padding: 0.6rem 0;
    }
}

.order-nowP {
    margin: 1rem 10%;
    padding: 0.8rem;
    border-radius: 12px;
    transition: all 0.2s linear;
    background-color: var(--main-color);
    text-align: center;
}

.order-nowP:hover {
    background-color: var(--main-text-color);
    transition: all 0.2s linear;

}

.order-nowP a {
    color: var(--sec-text-color);
}

.productDinfo h6 {
    opacity: 0.75;
}

.productDinfo .Pdesc {
    padding-top: 1rem;
}

.values {
    padding-top: 1rem;
    display: flex;
    justify-content: space-evenly;
}

.value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.value i {
    font-size: 2rem;
    color: var(--main-color);
}

.PHname {

    width: 40%;
    height: 1.5rem;
    overflow: hidden;
}

@media screen and (max-width: 1070px) {

    .productD {
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }

    .productDinfo {
        width: 80%;
        padding-top: 3rem;
    }

    .ProductD-Page {
        min-height: 100%;

    }

    .imgshowcase {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .Pimg-cover {
        position: relative;
        transition: all 0.3 linear;

        width: 100%;

        .glassIcon {
            display: none;
        }
    }

    .Pimgs {
        max-width: 100%;
        display: flex;
        flex-direction: row;
    }

    .Pimgs img {
        max-width: 100px;
    }

    .Pimg-cover img {
        width: 100%;

    }

}

@media screen and (max-width: 500px) {

    .Pdesc-2,
    .Pdesc-3 {
        font-size: 0.77rem;
    }

    .Pdesc-3 {
        width: 80% !important;
    }

    .value p {
        font-size: 0.6rem;
    }
}