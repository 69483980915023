/* .sidenav {
    position: absolute;
    z-index: 999;
    width: 300px;
    min-height: 100%;
    max-height: 100%;
    background-color: rgb(54, 54, 54);
} */

.sidenav {
    position: fixed;
    top: 0;
    left: 0%;
    width: 250px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transform: translateX(-0%);
    transition: transform 0.3s ease;
}

.sidenav-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: scroll;
    overflow: hidden;
    width: 100%;
    height: 90%;
    margin-top: 40%;
    padding: 20px 0;
}

.sidenav ul {
    list-style-type: none;
    padding: 0;
}

.sidenav ul li {
    margin: 4px 0;
}

.sidenav ul li a {
    text-decoration: none;
    color: #333;
}

.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.sidenav-open .sidenav {
    transform: translateX(0);
}

.sied-lngBtn {
    top: 8%;
    right: -60%;
}

.MsearchIcon {
    position: absolute;
    top: 7.5%;
    left: 10%;
    font-size: 1.5rem;
}

.sidenav .sec-navbar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin: 1rem 0 0 0;
    padding: 0;
    color: #333;
    background-color: transparent;
}

.sidenav .sec-navbar .dropdown-menu {
    width: 180%;
    left: 0%;
    right: 0%;
    height: 200px;
    overflow: scroll;
}

.sidenav .sec-navbar .dropdown-menu li a {
    padding: 0.35rem;
}

.sidenav .nav-items {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.sidefooter {
    width: 250px;
    height: 250px;
    background-color: var(--main-color);

    .SFH6 {
        color: #fff;


        h6 {
            margin: 0.8rem 0.6rem;
        }

    }

    .SFH7 {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 100%;
        padding-bottom: 2rem;

        h6 {
            padding-top: 0.5rem;
        }
    }

}

.sideFoter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: #fff;
    }

    a {
        color: var(--sec-color);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        margin: 0 0.5rem;
        padding: 0.7rem;
        border-radius: 0.8rem;
        background-color: #333;
    }

}

@media screen and (max-height: 670px) {

    .sied-lngBtn {
        top: 10%;
        right: -60%;
    }

    .MsearchIcon {
        position: absolute;
        top: 9.5%;
        left: 10%;
        font-size: 1.5rem;
    }
}