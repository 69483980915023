.ratingStars {
    font-size: 1.3rem;
}

@media screen and (max-width: 1050px) {
    .ratingStars {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .ratingStars {
        font-size: 0.8rem;
    }
}