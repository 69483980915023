.admin {
    display: flex;
    background-color: #fff;
}


.admin-container {
    width: 80%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1050px) {
    .admin {
        display: flex;
        align-items: center;
        flex-direction: column;
    }


}