.add-category .addproduct-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.product-images-preview {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 450px;
}

.add-images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:560px) {
    .add-category {
        transform: scale(0.9);
    }

    .product-images-preview {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        width: 250px;
    }
}