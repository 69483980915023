.modal-overlay {
    position: fixed;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.modal-content {
    position: relative;
    border-radius: 6px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 2rem 4rem;
    text-align: center;
    max-width: 90%;
    max-height: 80vh;
    overflow: hidden;
}

.modal-content img {
    padding: 2rem 1rem 0rem 1rem;
    max-width: 80%;
    max-height: 100%;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #fff !important;
}

.model-head {
    position: absolute;
    top: 0;
    color: var(--sec-text-color);
    width: 100%;
    padding: 0.25rem 0;
    border-radius: 5px 5px 0 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
}


.model-head h5 {
    margin-top: 0.5rem;
    font-size: 1.1rem;

}


.carousel-arrows {
    position: absolute;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    bottom: 44%;
    left: 50%;
    transform: translateX(-50%);
}

.prev-btn,
.next-btn {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    margin: 0 4px;
}

.prev-btn:hover,
.next-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}