.categoriesT {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.categoriesT table {
    background-color: var(--sec-color);
    padding: 1rem;
    width: 100%;
    border-collapse: collapse;
    margin: 1.5rem 0;
}

.categoriesT table th,
.categoriesT table td {
    border-radius: 1rem;
    padding: 1rem 4rem;
    border: 1px solid #22222270;
    text-align: left;
}

.categoriesT table th {
    font-weight: bold;
}

table button {
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

table button .delete {
    color: red;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    transition: all 0.2s linear;
    background-color: var(--main-color);
    color: var(--sec-text-color);
    cursor: pointer;
}

.pagination button:hover {
    transition: all 0.2s linear;
    background-color: var(--main-text-color);
}

.pagination button:disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
}

@media screen and (max-width: 450px) {

    .categoriesT table {
        width: 100%;
        padding: 0.5rem;
        margin: 0.2rem 0;

    }

    .Pimg img {
        width: 60px;
        height: 60px;
    }

    .categoriesT table th,
    .categoriesT table td {
        padding: 0.5rem;
        font-size: 0.6rem;
    }

}