.add-product {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    padding: 1.5rem 1.8rem;
    margin: 0.5rem 0.6rem;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    background-color: var(--sec-color);
}

.admin-page-container {
    width: 100%;
}

.addproduct-thumnail-img {
    max-width: 200px;
    display: flex;
    flex-direction: row;
}

.addproduct-thumnail-imgs {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.addproduct-thumnail-imgs img {
    width: 150px;
}

.addproduct-itemfield {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.addproduct-itemfieldd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.chick-boxs label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.6rem;
}

.chick-boxs input {
    margin: 0.5rem;
}

.addproduct-itemfield input,
.addproduct-itemfield select,
.addproduct-itemfield textarea {
    width: 350px;
}

.addproduct-itemfield textarea {
    height: 100px;
}


@media screen and (max-width:800px) {
    .addproduct-itemfield {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:560px) {
    .addproduct-thumnail-img {
        max-width: 100px;
        display: flex;
        flex-direction: row;
    }

    .addproduct-thumnail-imgs {
        display: flex;
        gap: 0.3rem;
        flex-wrap: wrap;
    }

    .addproduct-thumnail-imgs img {
        width: 70px;
    }

    .addproduct-itemfield input,
    .addproduct-itemfield select,
    .addproduct-itemfield textarea {
        width: 250px;
    }

    .addproduct-itemfieldd {
        flex-direction: column;
        align-items: center;
    }
}