.services {
    padding-bottom: 3rem;
}

/* .services .conatiner {
    width: 90% !important;
} */


.catgitems {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.catgitem {
    width: 450px;
    height: 270px;
    position: relative;
    display: flex;
    justify-content: center;
    transform: scale(1);
    transition: all 0.2s linear;

}

.catgitem:hover {
    transform: scale(1.1);

}

.catgitem img {
    border-radius: 1rem;
    box-shadow: 7px 7px 14px -7px var(--main-text-color);
}

.catgcontent {
    font-size: 1.7rem;
    color: var(--sec-text-color);
    position: absolute;
    bottom: 0.5rem;
    z-index: 1;
}

/* services Details strat */
.serv-page-header {
    position: relative;

}

.serv-page-header h1 {
    margin-top: 7.5px;
    color: var(--sec-text-color);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #3a59986e;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.serv-page-header h2 {
    bottom: -15%;
    color: var(--sec-text-color);
    font-size: 1.7rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 25%;
    background-color: #3a5998;
    border-radius: 20px;

}

.serv-page-header img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 30vh;
    object-fit: cover;
}

.serv-dics {
    margin: 1.5rem 0;
    width: 100%;
    padding: 1.5rem;
    border-radius: 20px;
    background: var(--sec-color);
    box-shadow: 7px 7px 14px -7px var(--main-text-color);
}

/* services Details ends */



@media screen and (max-width: 1600px) {
    .catgitems {
        gap: 2.8rem;
    }

    .catgitem {
        width: 400px;
        height: 220px;

    }

    .catgcontent {
        font-size: 1.5rem;
        bottom: 0.1rem;
    }

}

@media screen and (max-width: 1410px) {
    .catgitems {
        gap: 3.5rem;
    }

    .catgitem {
        width: 300px;
        height: 200px;
    }

}

@media screen and (max-width: 1360px) {
    .catgitems {
        gap: 2.5rem;
    }

    .catgitem {
        width: 315px;
        height: 185px;
    }

    .catgcontent {
        font-size: 1.5rem;
        bottom: 0.1rem;
    }

}

@media screen and (max-width: 1250px) {
    .catgitems {
        gap: 1.5rem;
    }

    .catgitem {
        width: 300px;
        height: 170px;
    }

    .catgcontent {
        font-size: 1.3rem;
        bottom: 0.1rem;
    }

}

@media screen and (max-width: 995px) {
    .serv-page-header h2 {
        bottom: -9%;
        width: 40%;
        height: 20%;
        font-size: 1.14rem;
        border-radius: 20px;

    }

}

@media screen and (max-width: 940px) {
    .catgitems {
        gap: 0.5rem;
    }

    .catgitem {
        width: 270px;
        height: 140px;
    }

    .catgcontent {
        font-size: 1rem;
        bottom: 0.1rem;
    }



}

@media screen and (max-width: 850px) {
    .catgitems {
        gap: 0.5rem;
        grid-template-columns: repeat(3, 1fr);
    }

    .catgitem {
        width: 250px;
        height: 120px;
    }

    .catgcontent {
        font-size: 0.8rem;
        bottom: 0.1rem;
    }

    .catgitem img {
        border-radius: 0.6rem;
        box-shadow: 7px 7px 14px -7px var(--main-text-color);
    }
}

@media screen and (max-width: 566px) {
    .serv-dics {
        width: 95%;

    }
}

@media screen and (max-width: 687px) {
    .catgitems {
        width: 100%;
        gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .services.catgitems {
        width: 100%;
        gap: 0.8rem;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .serv-page-header h2 {
        bottom: -8%;
        width: 65%;
        height: 15%;
        font-size: 1rem;
        border-radius: 20px;

    }

    .catgitem {
        width: 150px;
        height: 100px;
    }

    .catgcontent {
        font-size: 0.6rem;
        bottom: 0.1rem;
    }
}