.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 60px;
    background-color: var(--sec-color);
}


.user-content {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.admin-logo-content {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.nav-logo {
    height: 25px;
}

@media screen and (max-width:560px) {
    .nav-logo {
        height: 15px;
    }
}