.productsT {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productsT .addproduct-itemfield {
    margin: 0;
    padding-top: 1.5rem;
}

.productsT table {
    padding: 1rem;
    width: 90%;
    border-collapse: collapse;
    margin: 0.5rem 0;
}

.productsT table th,
.productsT table td {
    padding: 1rem;
    border: 3px solid #fff;
    text-align: left;
}

.productsT table tr {
    background-color: var(--sec-color);
    border-radius: 1rem;
}

.productsT table th {
    font-weight: bold;
}

.Pimg img {
    object-fit: cover;
    width: 100px;
    height: 100px;
}

.productsT td p {
    height: 5rem;
    padding: 0;
    margin: 0;
    overflow: scroll;
    overflow-x: hidden;
}

.productsT .modal-overlay {
    z-index: 999;
}

@media screen and (max-width: 450px) {

    .productsT {

        transform: scale(0.8);

        .modal-overlay {
            min-height: 90vh;
            transform: scale(1.2) !important;
        }
    }

    .productsT .productsT table {
        width: 90%;
        padding: 0.5rem;
        margin: 0.2rem 0;

    }


    .Pimg img {
        width: 60px;
        height: 60px;
    }

    .productsT table th,
    .productsT table td {
        padding: 0.5rem;
        font-size: 0.6rem;
    }

}