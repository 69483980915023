.search-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 750px;
}

.custom_input {
    display: flex;
    align-items: center;
    position: relative;
}


.searchIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconHide {
    display: none;

}

.svg_iconR {
    position: absolute;
    left: 10px;
    fill: var(--main-color);
    width: 18px;
    height: 18px;
}

.svg_icon {
    position: absolute;
    right: 20px;
    fill: var(--main-color);
    width: 18px;
    height: 18px;
}

.input {
    width: 100%;
    font-size: 18px;
    padding: 10px 45px;
    outline: none;
    background: #ffffff;
    color: #000000;
    border: none;
    border-radius: 30px;
    transition: 0.3s ease;

}

.input:focus {
    background: #f2f2f2;
    border: 1px solid #3a59984f;
    border-radius: 15px 15px 0 0;
}


.input::placeholder {
    color: #dddddd;
}


.result-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 20px 25px #222;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    max-height: 60vh;
    width: 100%;
    top: 101%;
    background-color: var(--sec-color);
    border-radius: 0 0 15px 15px;
}

.result-container button {
    margin: 1px;
    font-size: 0.8rem;
    padding: 1rem 2rem;
    background: #ffffff;
    color: #000000;
    transition: 0.3s ease;
    border: none;
}

.result-container::-webkit-scrollbar {
    width: 0px;
}

.result-card {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 0rem;
    width: 100%;
}

.result {
    display: flex;
    flex-direction: column;
    margin: 1px;
    font-size: 0.7rem;
    padding: 1rem 2rem;
    background: #ffffff;
    color: #000000;
    transition: 0.3s ease;
}

.result a {
    text-decoration: none;
    color: #000000;
}

.result div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.result div .ResContent {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 70%;

    p {
        height: 2rem;
        margin: 0 0.4rem;
        overflow: hidden;
    }
}

@media screen and (max-width: 1366px) {
    .search-card {
        width: 650px;
    }
}

@media screen and (max-width: 1250px) {
    .search-card {
        width: 500px;
    }
}

@media screen and (max-width: 1070px) {
    .search-card {
        width: 400px;
    }


}

@media screen and (max-width: 850px) {
    .search-card {
        margin-left: 3rem;
        width: 350px;

        input {
            height: 40px;
            font-size: 0.7rem;
        }
    }

}

@media screen and (max-width: 560px) {
    .result-container {
        width: 80vw;
        top: 110%;
        left: -40%;
        right: -40%;
        border-radius: 15px;
    }

    .result-card {
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        width: 100%;
    }

    .result {
        font-size: 0.5rem;
        padding: 0.5rem 1.2rem;
    }

    .result div .ResContent {
        p {
            margin: 0 0.2rem;
            height: 1rem;
        }
    }

    .search-card {
        margin-left: 1.7rem;
        width: 240px;

        input {
            height: 30px;
            font-size: 0.7rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .result-container {
        left: -40%;
        right: -40%;
    }
}

@media screen and (max-width: 450px) {
    .search-card {
        width: 200px;
        margin-left: 1.5rem;

        input {
            height: 30px;
            font-size: 0.7rem;
        }
    }

    .result-container {
        width: 80vw;
        top: 110%;
        left: -40%;
        right: -40%;
    }

    .result-card button a {
        font-size: 0.7rem;
    }

    .main-nav {
        padding: 0 5% 0 2%;
    }
}

@media screen and (max-width: 375px) {

    .result-container {

        left: -30%;
        right: -30%;
    }


}

@media screen and (max-width: 350px) {

    .result-container {
        width: 100%;
        left: 0;
        right: 0;
    }


}