.about-links {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem 0;
}

.about-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-color);
    padding: 6px 15px;
    border-radius: 5px;
    color: var(--sec-text-color);
    transition: all 0.2s linear;
}

.about-links a:hover {
    transition: all 0.2s linear;
    background: var(--main-text-color);
    color: var(--sec-text-color);
}

.serv-dics p {
    line-height: 2rem;
}

.serv-dics .servToPrud {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
    background: var(--main-color);
    padding: 0.5rem 0.8rem;
    border-radius: 18px;
    margin-bottom: 1.5rem;
    width: 50%;
    transition: all 0.2s linear;
}

.serv-dics .servToPrud:hover {
    transition: all 0.2s linear;
    background: var(--main-text-color);

}

@media screen and (max-width: 492px) {
    .about-links a {
        font-size: 0.7rem;
        padding: 4px 10px;

    }

    .serv-dics h5 {
        font-weight: bold;
        font-size: 0.9rem;
    }

    .serv-dics p {
        font-size: 0.8rem;
    }



}