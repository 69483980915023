.sidebar {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    gap: 1rem;
    max-width: 250px;
    min-height: 100vh;
    max-height: 100%;
    background: var(--sec-color);
}

.sidebar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    background-color: #fff;
    gap: 1rem;
}

.sidebar-item p {
    margin: 0.5rem;
}

@media screen and (max-width:1050px) {
    .sidebar {
        padding: 1.5rem 0;
        flex-direction: row;
        width: 100%;
        max-width: none;
        min-height: auto;
        height: auto;

    }

    .sidebar-item {
        margin: 0;
        font-size: 0.9rem;
        border-radius: 6px;
        background-color: #fff;
        gap: 1rem;
    }
}

@media screen and (max-width:560px) {
    .sidebar {
        padding: 1rem 0;
        flex-direction: row;
        width: 100%;
        max-width: none;
        height: auto;

    }

    .sidebar-item {
        margin: 0 0.5rem;
        font-size: 0.5rem;
        border-radius: 6px;
        background-color: #fff;
        gap: 0.2rem;
    }
}