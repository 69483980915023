@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

:root {
  --sec-color: #DFE3EE;
  --main-color: #3A5998;
  --main-color-op: #3a5998b9;
  --main-text-color: #222222;
  --main-text-color-op: #222222c5;
  --sec-text-color: #fff;
}

* {
  font-family: "Cairo", sans-serif;
  list-style: none;
  box-sizing: border-box;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: var(--main-text-color);
}

.containerr {
  width: 90%;
  margin: 0rem 4%;
}

section {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section-more-btn {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--main-color);
  margin-top: 2rem;
  text-align: center;
}

.sectionH {
  width: 98%;
  margin-bottom: 2rem;
  line-height: 0.5;
  text-align: center;
}

.sectionH h2 {
  color: var(--main-text-color);
  font-size: 2rem;
  position: relative;
}

.PagiNation {
  position: relative;
}

.modal-overlay {
  z-index: 1100 !important;
}

@media screen and (max-width: 1070px) {

  .sectionH h2 {
    font-size: 1.7rem;

  }

  .section-more-btn {
    font-size: 1rem;
  }
}